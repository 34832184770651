import React, { useState, useEffect, Component, Fragment } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import * as styles from "../components/pagestyle/projects.css";
import * as styles2 from "../components/animations/styledanimation.css";

import { fakeData } from "../components/animations/fakeData/fakeData";

//const space = <div id="tabulation">&nbsp;</div>;

export const query = graphql`
  query projectsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
    }
    projects: allSanitySampleProject {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              _id
              gatsbyImageData(placeholder: BLURRED)
            }
            alt
          }
          slug {
            current
          }
        }
      }
    }
  }
`;


const projectsPage = props => {
  const { data, errors } = props;
  useEffect(() => {
    document.querySelector('body').style.backgroundColor = '#fff';

    // If mobile ou tablette > Pas d'images
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      Array.prototype.slice.call(document.querySelectorAll('.img-cont'))
        .forEach(function (element) {
          element.remove();
        });
    }


  }, []);



  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
    : [];
  const pNtest = (data || {}).projects;


  //console.log(pNtest.edges[0].node.carousel[0]);


  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }


  const mousePosition = useMousePosition();

  //Offcanvas

  const state = {
    isOpen: false,
  }

  let open = () => {
    this.setState({ isOpen: true })
  }

  let close = () => {
    this.setState({ isOpen: false })
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} />
      <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        <ol className="index">
          {projectNodes.map((elem, index) => (
            <li key={elem.id}>
              <Link to={`/project/${elem.slug.current}`}>
                <h4><span className="tabular">0{index}</span><span>{elem.title}</span></h4>
                <div className='img-cont'>
                  {elem.mainImage && elem.mainImage.asset && (
                    <GatsbyImage
                      image={elem.mainImage.asset.gatsbyImageData}
                      alt={elem.title + ' image cover'}
                    />
                  )}
                </div>
              </Link>
            </li>

          ))}
        </ol>

        <div
          style={{ left: `${mousePosition.x}px`, top: `${mousePosition.y}px` }}
          className="cursor"
        ></div>
      </Container>
    </Layout >
  );
};

const useMousePosition = () => {
  const [
    mousePosition,
    setMousePosition
  ] = React.useState({ x: null, y: null });

  React.useEffect(() => {
    const updateMousePosition = ev => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };

    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  return mousePosition;
};

export default projectsPage;

